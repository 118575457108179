<template>
  <div class="dg-cpe__wrapper">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import "./assets/main.scss";
import { mapGetters } from "vuex";
export default {
  name: "CPE-Layout",
  mounted() {
    this.loadInitialData();
  },
  methods: {
    loadInitialData() {
      this.$http.get(`/api1/v2/industries/${this.$route.params.id}`).then(response => {
        this.$store.dispatch("setMappingData", {
          keyName: "SET_MAPPING_INDUSTRY_NAME",
          data: response.data.data.name,
        });
      });
      this.$http.get(`/api1/v2/industries/${this.$route.params.id}/data_exchanges`).then(response => {
        this.$store.dispatch("setMappingData", {
          keyName: "SET_DATA_EXCHANGES_TO_SAVE",
          data: response.data.data,
        });
      });
      this.$store.dispatch("loadOrganisations");
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-cpe__wrapper {
  position: relative;

  .dg-loader {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
</style>
